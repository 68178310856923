import React from 'react'
import { navigate } from 'gatsby'

import Layout from '../../modules/ResourcesLayout'
import { Card, Flex } from '../../components'
import { Typography, Input, Button, Select } from '../../fragments'
import { SecureAccessPageLink } from '../../lib/constants'
import path from '../../path'

import '../../styles/access/build.scss'

const BuildAccess = () => {
  const [allowed, setAllowed] = React.useState(false)
  const [pass, setPass] = React.useState('')

  const [startDate, setStartDate] = React.useState<string>('')
  const [endDate, setEndDate] = React.useState<string>('')
  const [options, setOptions] = React.useState<string[]>([])

  const onChangeStartDateHandler = (value: string) => setStartDate(value)
  const onChangeEndDateHandler = (value: string) => setEndDate(value)

  const onCopyLinkHandler = () => {
    const date = new Date()
    navigate(path.access.generated, {
      state: {
        startDate,
        endDate,
        options,
        expiresIn: Number(date.setMinutes(date.getMinutes() + 1)),
      },
    })
  }

  const onChangeOptionHandler = (value: string) => {
    setOptions(prev => {
      if (prev.includes(value)) return prev
      return [...prev, value]
    })
  }

  const onClickOptionHandler = (value: string) => {
    setOptions(prev => prev.filter(option => value !== option))
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const date = new Date()
    if (pass.includes(`${date.getHours()}@${date.getMinutes()}`))
      setAllowed(true)
  }
  const onChangePassHandler = (value: string) => setPass(value)

  return (
    <Layout title="Create Access Link">
      <div className="build-access">
        <Card className="build-access__card">
          <Card.Content>
            <Flex>
              <Flex.Row margin padding>
                <Typography size="medium">Create Access</Typography>
              </Flex.Row>
              {allowed ? (
                <>
                  <Flex.Row margin>
                    <Typography>Available options</Typography>
                  </Flex.Row>

                  <div className="build-access__options">
                    {options?.map(option => (
                      <div
                        key={option}
                        onClick={() => onClickOptionHandler(option)}
                      >
                        <Flex.Column
                          key={option}
                          className="build-access__option"
                        >
                          <Typography>
                            {
                              SecureAccessPageLink.find(
                                val => val.value === option
                              )?.label
                            }
                          </Typography>
                        </Flex.Column>
                      </div>
                    ))}
                  </div>

                  <Select
                    list={SecureAccessPageLink}
                    className="build-access__select"
                    onChange={onChangeOptionHandler}
                  />

                  <Flex.Column>
                    <Flex.Row margin>
                      <Typography>Start Date</Typography>
                    </Flex.Row>
                    <Input
                      type="datetime-local"
                      value={startDate}
                      onChange={onChangeStartDateHandler}
                      name="startDate"
                    />
                  </Flex.Column>
                  <Flex.Column>
                    <Flex.Row margin>
                      <Typography>End Date</Typography>
                    </Flex.Row>
                    <Input
                      type="datetime-local"
                      value={endDate}
                      onChange={onChangeEndDateHandler}
                      name="endDate"
                    />
                  </Flex.Column>
                  <Flex.Row margin className="build-access__action">
                    <Button size="medium" onClick={onCopyLinkHandler}>
                      CREATE
                    </Button>
                  </Flex.Row>
                </>
              ) : (
                <form onSubmit={onSubmitHandler}>
                  <Input
                    placeholder="Access Key"
                    value={pass}
                    onChange={onChangePassHandler}
                    type="password"
                  />
                </form>
              )}
            </Flex>
          </Card.Content>
        </Card>
      </div>
    </Layout>
  )
}

export default BuildAccess
